export const cartConsts = {
    tables: {
        "1": true,
        "2": true,
        "3": true,
        "4": true,
        "21": true,
        "22": true,
        "23": true,
        "24": true,
        "41": true,
        "42": true,
        "43": true,
        "44": true,
        "45": true,
        "A": true,
        "B": true,
        "CA": true,
        "CB": true,
        "C1": true,
        "C2": true,
        "C3": true,
        "C4": true,
        "C5": true,
        "C6": true,
        "C7": true,
        "C8": true,
        "C9": true,
        "C10": true,
        "admin": true
    },
    chTablePrefix: "門",
    actions: {
        add: "ADD_TO_CART",
        update: "UPDATE_CART",
        clear: "CLEAR_CART",
        updateExistingOrder: "UPDATE_EXISTING_ORDER",
        addToExistingOrder: "ADD_TO_EXISTING_ORDER"
    }
};